import axios from "axios";

export const getAssign = async (id: any) => {
    const serv = `https://www.rozetle.com:5005/api/assign/my/${id}`
   
    try {
        const response = await axios.get(serv);

       
        return response
    } catch (error) {
        console.error('Error fetching assignmentasdads data:', error);
    }
}

export const getInfo = async () => {
    const token = localStorage.getItem("auth-token")

    try {
        const response = await axios.get(`https://www.rozetle.com:5005/api/admin/getInfo`, {
            headers: {
                'auth-token': token
            },
        })
       
        return response?.data?.data
    } catch (error) {
        console.error('Error fetching getInfo data:', error);

    }
}

export const getMySent = async () => {
    const token = localStorage.getItem("auth-token")

    try {
        const response = await axios.get(`https://www.rozetle.com:5005/api/assign2/mySent`, {
            headers: {
                'auth-token': token
            },
        })
        return response?.data
    } catch (error) {
        console.error('Error fetching getMySent data:', error);

    }
}