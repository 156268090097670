import axios from "axios";

export const getAllBadges = async () => {
    try {
        const token = localStorage.getItem("auth-token")

        const response = await axios.get(`https://www.rozetle.com:5005/api/badge/getAll`, {
            headers: {
                'auth-token': token
            },
        });

        return response.data

    } catch (error) {
        console.error('Badge failed', error);
        return false
    }
}
export const getAdminBadges = async () => {
    try {
        const token = localStorage.getItem("auth-token")

        const response = await axios.get(`https://www.rozetle.com:5005/api/admin/getAllBadges`, {
            headers: {
                'auth-token': token
            },
        });
        return response.data
    } catch (error) {
        console.error('Badge failed', error);
        return false
    }
}

export const createBadge = async (formData) => {

    try {
        const token = localStorage.getItem("auth-token")


        const response = await axios.post('https://rozetle.com:5005/api/admin/badge/create', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'auth-token': token,
            },
        });
        if (response.status === 201) {
            return true
        } else {
            console.log('Rozet oluşturma başarısız');
            return false
        }
    } catch (error) {
        console.error('Bir hata oluştu:', error);
        return false
    }
};

//delete badge
export const deleteBadge = async (badgeId) => {
    const token = localStorage.getItem("auth-token")
    try {
        const response = await axios.delete(`https://www.rozetle.com:5005/api/admin/badge/delete`, {
            headers: {
                'auth-token': token
            },
            data: {
                badgeId
            }
        });
        return true
    } catch (error) {
        console.error('Silme Başarısız', error);
        return false
    }
}

export const updateBadge = async (formData) => {
    const token = localStorage.getItem("auth-token")
    try {
        const response = await axios.put(`https://www.rozetle.com:5005/api/admin/badge/update`, formData, {
            headers: {
                'auth-token': token
            },
        });
        return true
    } catch (error) {
        console.error('Badge failed', error);
        return false
    }

}


