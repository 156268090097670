import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Textarea,
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { getAvailableBadges } from "../services/badgeService.ts";
import Loading from "./Loading.jsx";
import { sendBadges } from "../services/adminService.ts";

const KurumSendBadgeDialog = ({ setSelectedBadge, selectedBadge }) => {
  const [inputText, setInputText] = useState("");
  const [description, setDescription] = useState("");
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingLabel, setLoadingLabel] = useState("");

  const handleInputChange = (e) => {
    const text = e.target.value.toLowerCase();
    setInputText(text);
    const linesArray = text.split("\n").filter((line) => line.trim() !== "");
    setList(linesArray);
  };
  return (
    <Dialog open={selectedBadge != null} size={"xl"}>
      <DialogHeader>
        <div className="flex justify-between w-full">
          <p>{`${selectedBadge?.title || " "} Rozetini Gönderiyorsunuz`}</p>
          <p className="down-md:hidden">Rozetle Bence</p>
        </div>
      </DialogHeader>
      <div className="p-4  !max-h-[calc(100vh-15rem)]  overflow-y-scroll flex flex-col items-center justify-center">
        <div className="pt-16 ">
          <img
            className="h-64 w-64 rounded-lg shadow-xl hover:scale-[105%] transition-all border-2 border-gray-500"
            src={selectedBadge?.badgeImg}
          />
        </div>
        <div className="p-4">
          <label
            htmlFor="message"
            className="block text-sm font-medium text-gray-700"
          >
            Alıcı Listesi
          </label>
          <textarea
            id="message"
            name="message"
            rows="4"
            value={inputText}
            onChange={handleInputChange}
            className=" p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
          ></textarea>
          <label className="text-red-700">
            {selectedBadge?.restCount != -999 &&
            list.length > selectedBadge?.restCount ? (
              <>
                Bu rozet için gönderilebilecek maksimum rozet sayısına ulaştınız
                <br />
              </>
            ) : (
              ""
            )}
          </label>

          <label className="text-gray-700 text-xs">
            xxx@mail.com ya da 5xx xxx xxxx
            <br />
            *Her satır yeni bir alıcı
          </label>
          <br />

          <p className="text-sm">
            {"Gönderilecek rozet sayısı:" + list.length}
          </p>
          <label
            htmlFor="message"
            className="block text-sm font-medium text-gray-700 pt-3 "
          >
            Açıklama
          </label>
          <textarea
            id="message"
            name="message"
            rows="4"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className=" p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
          ></textarea>
        </div>

        {loading ? <Loading label={loadingLabel} /> : <></>}
        <ToastContainer />
      </div>
      {/* <DialogBody className="flex flex-col items-center py-6 down-md:px-10 h-[80%] overflow-scroll">
          <div className="flex flex-col gap-10 md:gap-5 sm:gap-2 items-center">
            <div>
              <img
                className="h-64 w-64 rounded-lg shadow-xl hover:scale-[105%] transition-all border-2 border-gray-500"
                src={selectedBadge?.badgeImg}
              />
            </div>

            <div className="!w-[500px]">
              <Textarea
                size="lg"
                className="w-[500px]"
                value={inputText}
                onChange={handleInputChange}
                variant="outlined"
                label="Alıcı Listesi"
              />
              <label className="text-gray-700 text-xs">
                xxx@mail.com ya da 5xx xxx xxxx
                <br />
                *Her satır yeni bir alıcı
              </label>
              <br />
              <p className="text-sm">
                {"Gönderilecek rozet sayısı:" + list.length}
              </p>
            </div>
            <Textarea
              size="md"
              className="w-[500px]"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              variant="outlined"
              label="Açıklama"
            />
          </div>

          {loading ? <Loading label={loadingLabel} /> : <></>}
          <ToastContainer />
        </DialogBody> */}
      <DialogFooter>
        <Button
          variant="text"
          color="red"
          onClick={() => setSelectedBadge(null)}
          className="mr-1"
        >
          <span>Cancel</span>
        </Button>
        <Button
          variant="gradient"
          onErrorCapture={"asd"}
          disabled={
            list.length === 0 ||
            loading === true ||
            (selectedBadge?.restCount != -999 &&
              list.length > selectedBadge?.restCount)
          }
          color="green"
          onClick={async () => {
            try {
              setLoadingLabel("Rozetleniyor...");
              setLoading(true);
              await sendBadges({
                badgeId: selectedBadge?._id, //badge ne gelmesi gerek bak
                description: description,
                receiversData: list,
              }).then((res) => {
                if (res === 200) {
                  toast.success("Rozet Gönderme işlemi başarılı");
                  setDescription("");
                  setInputText("");
                  setList([]);
                } else {
                  toast.error("Bir hata oluştu");
                }
              });
            } catch (error) {
              console.log(error);
              toast.error("Bir hata oluştu");
            } finally {
              setLoading(false);
            }
            setSelectedBadge(null);
          }}
        >
          <span>Gönder</span>
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default KurumSendBadgeDialog;
