import React, { useEffect, useState } from "react";
import { BadgeCard, Loading, UpdateBadge } from "../../components";
import { getAdminBadges, getAllBadges } from "../../services/badgeService.ts";
import { ToastContainer, toast } from "react-toastify";
import { Input } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedBadge } from "../../stores/badge.js";

const Badges = () => {
  const [allBadges, setAllBadges] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const selectedRecord = useSelector((state) => state.badge.selected);

  async function fetchBadges() {
    try {
      setLoading(true);
      const response = await getAdminBadges();
      if (response) {
        setAllBadges(response.data);
      } else {
        toast.error("Rozetle Getirilemedi");
      }
    } catch (error) {
      toast.error("Rozetle Getirilemedi");
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchBadges();
  }, []);
  const filteredData = allBadges?.filter((item) => {
    if (!allBadges) {
      return [];
    }
    return item?.badge.title.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleSelect = (selectedBadge) => {
    dispatch(setSelectedBadge(selectedBadge));
  };
  useEffect(() => {});
  return (
    <div className="flex flex-col h-full w-full gap-5  items-center overflow-y-scroll scrollbar scrollbar-thumb-gray-800 scrollbar-track-teal-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full hover:scrollbar-thumb-teal-400 active:scrollbar-thumb-teal-500 scrollbar-w-5">
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-5">
          <label>Ara </label>
          <input
            className="outline-none px-2.5 py-0.5 rounded-lg"
            type="search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        {`Toplam ${allBadges?.length || ""} kayıtlı Rozet`}
      </div>

      {filteredData ? (
        filteredData.map((badge, index) => (
          <BadgeCard
            select2Edit={handleSelect}
            fetchBadges={fetchBadges}
            data={badge}
            key={index}
          />
        ))
      ) : (
        <></>
      )}
      <ToastContainer />
      {loading ? <Loading /> : <></>}
      {selectedRecord ? (
        <UpdateBadge
          fetchBadges={fetchBadges}
          select2Edit={handleSelect}
          badge={selectedRecord}
          open={selectedRecord !== null}
          setOpen={() => {}}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Badges;
