import GifGetBadge1 from "./get-badge-1.gif"
import GifGetBadge2 from "./get-badge-2.gif"
import GifGetBadge3 from "./get-badge-3.gif"
import GifGetBadge4 from "./get-badge-4.gif"
import GifGetBadge5 from "./get-badge-5.gif"
import GifGetBadge6 from "./get-badge-6.gif"
import GifGetBadge7 from "./get-badge-7.gif"
import GifGetBadge8 from "./get-badge-8.gif"
import GifGetBadge9 from "./get-badge-9.gif"
import GifGetBadge10 from "./get-badge-10.gif"


export const GifGetBadge =
    [
        GifGetBadge1,
        GifGetBadge10,
        GifGetBadge2,
        GifGetBadge3,
        GifGetBadge4,
        GifGetBadge5,
        GifGetBadge6,
        GifGetBadge7,
        GifGetBadge8,
        GifGetBadge9
    ]