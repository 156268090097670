import { Typography } from "@material-tailwind/react";
import React, { useState } from "react";
import {
  IconHistoryList,
  IconHome,
  IconList,
  IconLogOut,
  IconRightChevron,
  ImgLogo,
} from "../assets/images";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "../stores/Page";

const KurumSidebar = () => {
  const [wide, setWide] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const page = useSelector((state) => state.page.current);
  //setPage with dispatch by what is the current path /xxx

  return (
    <div
      className={`w-full  ${
        wide ? "max-w-[20rem]" : "max-w-[30px]" //rem değişecek
      }  flex flex-row  transition-all shadow-xl justify-between shadow-blue-gray-900/5`}
    >
      <div
        className={`h-full gap-2 px-4 sm:px-1 md:px-1 ${
          wide ? "flex flex-col" : "hidden"
        }`}
      >
        <div className="mb-2 p-2">
          <Typography
            className="border-b border-gray-400"
            variant="h5"
            color="blue-gray"
          >
            <img src={ImgLogo} alt="app logo" />
          </Typography>
        </div>
        <button
          onClick={() => {
            navigate("/kurum/anasayfa");
            dispatch(setPage("anasayfa"));
          }}
          className={`${
            page === "anasayfa"
              ? "bg-teal-400 up-sm:translate-x-4 text-white"
              : "text-black"
          } shadow-lg hover:bg-teal-300 sm:px-1 md:px-1 px-3 py-2 rounded-lg hover:text-white   transform  up-md:hover:translate-x-6 transition-transform`}
        >
          <div className="flex gap-3 sm:gap-1 items-center sm:text-sm">
            <img
              src={IconHome}
              alt="test"
              className="h-5 w-5 sm:h-4 sm:w-4 rounded "
            />
            Anasayfa
          </div>
        </button>
        <button
          onClick={() => {
            navigate("/kurum/rozetler");
            dispatch(setPage("rozetler"));
          }}
          className={`${
            page === "rozetler"
              ? "bg-teal-400 up-md:translate-x-4 text-white"
              : "text-black"
          } shadow-lg hover:bg-teal-300 sm:px-2 md:px-2 px-3 py-2 rounded-lg hover:text-white   transform  up-sm:hover:translate-x-6 transition-transform`}
        >
          <div className="flex gap-3 md:gap-1 sm:gap-1 items-center sm:text-sm">
            <img
              src={IconList}
              alt="test"
              className="h-5 w-5 sm:h-4 sm:w-4 rounded "
            />
            Rozet Gönder
          </div>
        </button>
        <button
          onClick={() => {
            navigate("/kurum/gonderilenler");
            dispatch(setPage("gonderilenler"));
          }}
          className={`${
            page === "gonderilenler"
              ? "bg-teal-400 up-md:translate-x-4 text-white"
              : "text-black"
          } shadow-lg hover:bg-teal-300 sm:px-2 md:px-2 px-3 py-2 rounded-lg hover:text-white   transform  up-sm:hover:translate-x-6 transition-transform`}
        >
          <div className="flex gap-3 md:gap-1 sm:gap-1 items-center sm:text-sm">
            <img
              src={IconHistoryList}
              alt="test"
              className="h-5 w-5 sm:h-4 sm:w-4 rounded "
            />
            Gönderilenler
          </div>
        </button>
      </div>
      <div className="py-10 px-1 flex justify-center">
        <button
          onClick={() => {
            setWide(!wide);
          }}
          className=" h-7 w-7  sm:h-5 sm:w-5 flex justify-center"
        >
          <img
            className={`${
              wide ? "rotate-180" : "hover:rotate-180"
            } transition-all duration-200 w-6 h-6 transform sm:h-4 sm:w-4`}
            src={IconRightChevron}
            alt="right arr"
          />
        </button>
      </div>
    </div>
  );
};

export default KurumSidebar;
