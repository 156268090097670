import React from "react";
import { GifGetBadge } from "../../assets/gifs/celebrate";

const KurumHome = () => {
  return (
    <div className="w-full flex justify-center">
      <div className="!max-h-[calc(100vh-200px)] max-w-[1200px] overflow-y-scroll scrollbar-hide text-gray-800 flex flex-col gap-3 items-center bg-white px-4 py-6 sm:p-2 rounded-lg shadow-lg text-justify">
        <label className="text-xl font-semibold text-gray-900">
          Rozetle Kurum üzerinden rozetlerinizi göndermek çok daha kolay ve
          eğlenceli. 🏆
        </label>
        <br />
        <div className="w-full bg-rzt_purple_light !h-[2px] text-rzt_purple_light mb-4">
          .
        </div>
        <b className="text-gray-900">Toplu Rozet Gönderme:</b>
        Bir etkinlik, toplantı veya rozetlemek istediğiniz bir topluluk mu var?
        <br />
        Ekip arkadaşlarınıza veya katılımcılara kolayca teşekkür edin ve
        başarılarını öne çıkarın. Hadi, birlikte büyük anılar biriktirelim! 📧
        <br />
        Rozet gönder bölümünden seçtiğiniz rozeti alıcı mail adreslerine veya
        telefon numaralarına toplu gönderim yapabilirsiniz.
        <div className="w-full bg-rzt_purple_light !h-[2px] text-rzt_purple_light mb-4">
          .
        </div>
        <b className="text-gray-900">Özel Rozet Talepleri:</b>
        Kendi özel rozetinizi mi istiyorsunuz? Rozetle size özel bir şekilde
        parlamak için bir fırsat sunuyor! Sadece rozetlebence@gmail.com hesabına
        bir e-posta gönderin göndermek istediğiniz tasarımı belirtin ya da
        istediğiniz özel rozeti tarif edin size özel olarak tasarlanmış bir
        rozetle gurur duyun! 🎉
        <div className="w-full bg-rzt_purple_light !h-[2px] text-rzt_purple_light mb-4">
          .
        </div>
        <b className="text-gray-900">Eğlenceli Rozetler:</b>
        Sadece işle sınırlı değil! Rozetle, eğlenceli ve benzersiz rozetlerle
        dolu bir dünyaya kapı aralıyor. Kendinizi ifade edin, espri yapın veya
        sadece gününüzü renklendirin! Bu harika özellikleri keşfetmek için hemen
        Rozetlemeye başlayın tanıdıklarınızı rozetlemeye teşvik edin. Birlikte
        daha fazla başarıya ve eğlenceye hazır olun!
        <br></br>
        <b className="text-gray-900">
          Unutmayın, başarıları kutlamak ve eğlenmek her zaman bir rozet kadar
          yakın! 🚀🌟
        </b>
        <img className="max-w-[400px]" src={GifGetBadge[7]} />
      </div>
    </div>
  );
};

export default KurumHome;
