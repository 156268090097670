import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { getMySent } from "../../services/assignService.ts";
import {
  Badge,
  Carousel,
  Dialog,
  DialogBody,
  DialogHeader,
  Input,
} from "@material-tailwind/react";
import { IconDownloadXls, IconSearch } from "../../assets/images/index.js";
import { exportSentBadges } from "../../services/adminService.ts";
import Loading from "../../components/Loading.jsx";

const KurumSent = () => {
  const [sentBadges, setSentBadges] = useState([]);
  //fetch sent badges
  const [openImage, setOpenImage] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);

  const handleOpen = () => setOpenImage(null);
  
  const fetchSent = async () => {
    setLoading(true);
    const res = await getMySent();
    if (res.success) {
      setSentBadges(res.data);
    } else {
      toast.error("Bir hata");
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchSent();
  }, []);

  const groupedArray = sentBadges?.reduce((result, current) => {
    // badgeId'yi anahtar olarak kullanarak gruplama yap
    const key = current.badgeId._id;

    // Gruplanmış veri zaten varsa, yeni nesneyi ekleyerek güncelle
    if (result[key]) {
      result[key].push(current);
    } else {
      // Gruplanmış veri yoksa, yeni bir grup oluştur
      result[key] = [current];
    }

    return result;
  }, {});

  // Gruplanmış veriyi diziye dönüştür
  const groupedArrayAsArray = Object.values(groupedArray);

  const filteredData = groupedArrayAsArray?.filter((item) => {
    if (!groupedArrayAsArray) {
      return [];
    }
    return (
      item[0].badgeId.title.toLowerCase().includes(searchText.toLowerCase()) &&
      item[0].badgeId.categoryId.title.toLowerCase()
    );
  });
  return (
    <div className="flex justify-center">
      <div className="h-full max-w-[900px] w-full">
        <div className="flex gap-2 items-center py-4">
          <Input
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            type="text"
            label="Ara"
            className="bg-white w-[250px]"
          />
          <img className="h-5 w-5" src={IconSearch} alt="search" />
        </div>
        <div className=" min-h-[200px] !max-h-[calc(100vh-200px)] overflow-y-scroll flex flex-col gap-3  py-2     items-center ">
          {sentBadges?.length > 0 ? (
            filteredData?.map((arr, index) => (
              <div
                className={`bg-white w-full rounded-lg md:p-4 up-md:px-6 p-4 sm:p-2 justify-between sm:grid sm:grid-cols-1 sm:justify-center flex flex-wrap items-center shadow`}
              >
                <div className="text-black text-2xl font-semibold flex sm:grid sm:grid-cols-1 gap-2 items-center">
                  <Badge content={arr.length} className="">
                    <img
                      onClick={() => {
                        setOpenImage(arr[0].badgeId);
                      }}
                      className="rounded-lg shadow-md h-40 w-40 cursor-pointer place-self-center"
                      src={arr[0].badgeId.badgeImg}
                      alt={arr[0].badgeId.title}
                    />
                  </Badge>
                  <label className="font-semibold text-xl ">
                    {arr[0].badgeId.title} <br />
                    <label className="text-sm font-normal">
                      {arr[0].badgeId.categoryId.title}
                    </label>
                  </label>
                </div>
                <button
                  onClick={async () => {
                    if (
                      !window.confirm(
                        `${arr[0].badgeId.title} rozetini gönderdikleriniz indirilecek emin misiniz?`
                      )
                    )
                      return;

                    setLoading(true);
                    await exportSentBadges(arr[0].badgeId._id);
                    setLoading(false);
                  }}
                >
                  {" "}
                  <img src={IconDownloadXls} alt="" />
                </button>
              </div>
            ))
          ) : (
            <>
              Henüz hiç Rozet göndermediniz <br />
              Şimdi Hemen Rozetle Bence
            </>
          )}

          <ToastContainer />
          {loading ? <Loading /> : <></>}
        </div>
        <Dialog open={openImage} size={"xs"} handler={handleOpen}>
          <DialogHeader>{openImage?.title || ""}</DialogHeader>
          <DialogBody>
            <div className="flex justify-center">
              <img
                className="rounded-lg w-[60%] sm:w-[95%] md:w-[80] max-w-[500px]"
                src={openImage?.badgeImg}
                alt=""
              />
            </div>
          </DialogBody>
        </Dialog>
      </div>
    </div>
  );
};

export default KurumSent;
