import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { getMyInfo, login } from "../../services/authService.ts";
import {
  IconHidePass,
  IconViewPass,
  ImgLogo,
} from "../../assets/images/index.js";
import { useDispatch } from "react-redux";
import { setPage } from "../../stores/Page.js";
import { setAuth } from "../../stores/User.js";

const KurumLogin = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [visible, setVisible] = useState(false);
  const handleChangeVisible = () => {
    setVisible(!visible);
  };
  const notify = () => toast.error("Giriş Yapılamadı !");
  const dispatch = useDispatch();

  const handleLogin = async () => {
    let result;
    result = await login(data.email, data.password);
    const userInfo = await fetchInfo();

    if (result) {
      if (userInfo && userInfo.data.role == 1) {
        navigate("/kurum/anasayfa");
        dispatch(setPage("anasayfa"));
        dispatch(setAuth(userInfo.data));
      } else {
        localStorage.removeItem("auth-token");
        toast.error("Giriş Başarısız: " + "Yetkisiz Erişim isteği");
      }
    } else {
      toast.error("Giriş Başarısız: " + result?.message || "");
    }
  };

  async function fetchInfo() {
    const response = await getMyInfo();
    return response;
  }
  useEffect(() => {
    const token = localStorage.getItem("auth-token");
    if (token) {
      const result = fetchInfo();

      if (result.success) {
        if (result && result.data.role == 1) {
          window.location.href = "/kurum/anasayfa";
          dispatch(setPage("anasayfa"));
          dispatch(setAuth(result.data));
        } else {
          toast.error("Giriş Başarısız: " + "Yetkisiz Erişim isteği");
        }
      } else {
        toast.error(result?.message);
      }
    }
  }, []);
  return (
    <div
      className={
        "flex justify-center items-center w-screen h-screen bg-rzt_bg text-sm "
      }
    >
      <div className="max-w-[780px] w-full sm:h-full justify-center bg-gradient-to-t from-rzt_blue to-rzt_purple border  px-5 pb-5  rounded-lg shadow-md flex flex-col overflow-y-scroll scrollbar-hide py-24 items-center ">
        <div
          className={
            "font-bold text-2xl sm:text-xl text-white bg-rzt_blue_dark sm:bg-white sm:text-black  flex flex-col items-center gap-2  px-8 py-2 rounded-t-xl -translate-y-5 "
          }
        >
          <div>
            {" "}
            <img className="rounded-lg w-80 sm:w-60" src={ImgLogo} alt="" />
          </div>
          Kurum Olarak Giriş
        </div>

        <div className="flex flex-col gap-7 my-4">
          <div className="flex items-center relative z-0 rounded-br-xl rounded-tl-xl bg-gray-100 px-2  group">
            <input
              type="email"
              name="email"
              id="email"
              value={data.email}
              className="block py-2.5 px-1 w-full text-sm outline-none text-gray-700 bg-transparent appearance-none peer"
              placeholder=" "
              required
              onChange={(e) => {
                setData({ ...data, email: e.target.value });
              }}
            />
            <label
              htmlFor="email"
              className="peer-focus:font-medium absolute translate-x-2 text-sm text-white duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-white  peer-placeholder-shown:scale-100 peer-placeholder-shown:text-gray-500 peer-placeholder-shown:translate-y-0 peer-focus:scale-[85%] peer-focus:-translate-y-[26px]"
            >
              E Posta
            </label>
          </div>
          <div className="flex items-center relative z-0 rounded-br-xl rounded-tl-xl bg-gray-100 px-3  group">
            <input
              type={visible ? "text" : "password"}
              name="password"
              id="password"
              value={data.password}
              className="block py-2.5 px-1 w-full text-sm outline-none text-gray-700 bg-transparent appearance-none peer"
              placeholder=" "
              required
              onChange={(e) => {
                setData({ ...data, password: e.target.value });
              }}
            />
            <label
              htmlFor="email"
              className="peer-focus:font-medium absolute translate-x-2 text-sm text-white duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-white  peer-placeholder-shown:scale-100 peer-placeholder-shown:text-gray-500 peer-placeholder-shown:translate-y-0 peer-focus:scale-[85%] peer-focus:-translate-y-[26px]"
            >
              Şifre
            </label>
            <button onClick={handleChangeVisible}>
              <img
                className="w-5 h-5 outline-none"
                src={visible ? IconHidePass : IconViewPass}
                alt="hide view"
              />
            </button>
          </div>
          <div className="flex justify-center">
            <button
              onClick={handleLogin}
              className={
                "bg-rzt_bg hover:bg-rzt_gray_dark transition-all px-4 font-semibold text-base  py-1.5 rounded-lg  "
              }
            >
              Giriş Yap
            </button>
          </div>
          <div>
            <p className="text-sm text-white text-center">
              Kurumsal Hesap Edinmek için <br />{" "}
              <b className="font-semibold">rozetlebence@gmail.com</b> ile
              iletişime geçebilirsiniz
            </p>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default KurumLogin;
