import {
  Checkbox,
  Chip,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Input,
  Option,
  Select,
  Typography,
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

import { createBadge, updateBadge } from "../services/badgeService.ts";
import { getAllCategory } from "../services/categoryService.ts";
import { BadgeNull } from "../assets/fakeData/index.js";
import { getAllUsers } from "../services/authService.ts";
import { IconClose } from "../assets/images/index.js";
import { getTheUsers } from "../services/adminService.ts";
import Loading from "./Loading.jsx";
const convertDate = (date) => {
  const inputDate = new Date(date);
  const gun = inputDate.getDate();
  const ay = inputDate.getMonth() + 1; // Ay değeri 0-11 arasında olduğu için 1 ekleyin
  const yil = inputDate.getFullYear();
  const saat = inputDate.getHours();
  const dakika = inputDate.getMinutes();
  const formattedDate = `${gun}.${ay}.${yil} - ${saat}:${dakika}`;
  return formattedDate;
};

const UpdateBadge = ({ open, badge, setOpen, select2Edit, fetchBadges }) => {
  const [badgeData, setBadgeData] = useState({
    title: badge.title,
    categoryId: badge.categoryId._id,
    totalCount: badge.totalCount,
    price: badge.price,
    attainerRoles: badge.attainerRoles,
    badgeImg: badge.badgeImg,
  });
  const [searchUser, setSearchUser] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingLabel, setLoadingLabel] = useState("");
  const [categories, setCategories] = useState();
  const [imgPreview, setImgPreview] = useState();
  async function fetchCategories() {
    try {
      const response = await getAllCategory();
      if (response) {
        setCategories(response.data);
      }
    } catch (error) {
      toast.error("kategoriler getirilemedi");
      console.log(error);
    } finally {
    }
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setBadgeData({ ...badgeData, badgeImg: file });
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImgPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImgPreview(null);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBadgeData({ ...badgeData, [name]: value });
  };
  const handleCheckBox = (e) => {
    if (
      badgeData?.attainerRoles?.includes(parseInt(e.target.value)) ||
      badgeData?.attainerRoles?.includes(e.target.value)
    ) {
      setBadgeData({
        ...badgeData,
        attainerRoles: badgeData?.attainerRoles?.filter(
          (r) => r != e.target.value
        ),
      });
    } else {
      setBadgeData({
        ...badgeData,
        attainerRoles: [...badgeData.attainerRoles, e.target.value], //fvgggggggggggggggg
      });
    }
  };

  async function fetchUsers() {
    try {
      setLoadingLabel("Kullanıcılar getiriliyor...");
      setLoading(true);
      const response = await getAllUsers();
      if (response) {
        setUsers(response);
      }
    } catch (error) {
      toast.error("Kullanıcılar getirilemedi");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchUsers();
    fetchCategories();
  }, []);

  const filteredData = users?.filter((item) => {
    if (!users) {
      return [];
    }
    return (
      item?.name.toLowerCase().includes(searchUser?.toLowerCase()) ||
      item?.surname.toLowerCase().includes(searchUser?.toLowerCase()) ||
      item?.nickName.toLowerCase().includes(searchUser?.toLowerCase()) ||
      item?.phone.replace(/\s/g, "").includes(searchUser?.replace(/\s/g, ""))
    );
  });

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("badgeId", badge._id);

    if (badgeData?.title != badge.title) {
      formData.append("title", badgeData.title);
    }
    if (badgeData?.categoryId != badge.categoryId._id) {
      formData.append("categoryId", badgeData.categoryId);
    }
    if (badgeData?.totalCount != badge.totalCount) {
      formData.append("totalCount", badgeData.totalCount);
    }
    if (badgeData?.price != badge.price) {
      formData.append("price", badgeData.price);
    }
    if (badgeData?.attainerRoles != badge.attainerRoles) {
      formData.append("attainerRoles", badgeData.attainerRoles);
    }
    if (imgPreview) {
      formData.append("badgeImg", badgeData.badgeImg);
    }
    try {
      setLoadingLabel("Rozet güncelleniyor...");
      setLoading(true);
      const response = await updateBadge(formData);
      if (response) {
        toast.success("Başarılı");
        await fetchBadges();
        select2Edit(null);
      } else {
        toast.error("Bir hata oluştu formdata");
      }
    } catch (error) {
      console.log(error);
      toast.error("Bir hata oluştu formdata");
    } finally {
      setLoading(false);
    }
  };
  return (
    <Dialog size="xxl" open={open} handler={setOpen}>
      <DialogHeader>ROZET GÜNCELLE</DialogHeader>
      <hr className="my-3" />
      <DialogBody>
        <div className="grid grid-cols-3 down-lg:grid-cols-1 overflow-y-scroll gap-3  ">
          <div className="flex flex-col items-center gap-3 p-4">
            <label className="text-gray-900 font-semibold">ESKİ ROZET</label>
            <img
              className="max-w-[300px] w-[50%] min-w-[200px]"
              src={badge.badgeImg || BadgeNull}
              alt="badge"
            />
            <div className="flex flex-col gap-3">
              <div className="flex ">
                <b className="text-gray-800 font-semibold text-2xl">
                  {badge?.title || "Rozet Başlığı"}
                </b>
              </div>

              <div className="flex gap-2">
                <b>Kategori:</b>{" "}
                {badge?.categoryId?.title || "Rozet Kategorisi"}
              </div>
              <div className="flex gap-2">
                <b>Toplam:</b>{" "}
                {badge?.totalCount === -999
                  ? "Sınırsız"
                  : badge?.totalCount || "Toplam Stok"}
              </div>
              <div className="flex gap-2">
                <b>Kalan:</b>{" "}
                {badge?.restCount === -999
                  ? "Sınırsız"
                  : badge?.restCount || "Kalan Stok"}
              </div>
              <div className="flex gap-2">
                <b>Ücret:</b> {badge?.price || "Ücret"}
              </div>
              <div className="flex gap-2">
                <b>Roller:</b> {badge?.attainerRoles || "Yetkililer"}
              </div>
              <div className="flex gap-2">
                <b>Oluşturma tarihi:</b>{" "}
                {convertDate(badge?.createdDate) || "Tarih"}
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center gap-4 p-4 border-x-2">
            <div className="mb-4 flex flex-col gap-2 items-center">
              {imgPreview && (
                <div className="">
                  <label className="block text-sm font-medium text-gray-700">
                    Resim Önizleme
                  </label>
                  <img
                    src={imgPreview}
                    alt="Preview"
                    className="h-32 w-32 object-contain rounded-md shadow-lg ring-2 ring-blue-gray-400 flex items-center justify-center"
                  />
                </div>
              )}
              <input
                type="file"
                name="badgeImg"
                accept="image/*"
                onChange={handleFileChange}
                className="mt-1 p-2 bg-gray-400 rounded-md"
              />

              <label className="text-xs text-gray-600">
                {" "}
                Seçilen resimlerin 1:1 olduğundan emin olun
              </label>
            </div>
            <Input
              variant="outlined"
              label="Rozet Başlığı"
              name="title"
              color="deep-purple"
              type="text"
              value={badgeData?.title}
              onChange={handleInputChange}
            />
            <div className="w-full">
              <Select
                size="lg"
                label="Kategori seç"
                name="categoryId"
                required
                onChange={(selectedCategoryId) =>
                  setBadgeData({ ...badgeData, categoryId: selectedCategoryId })
                }
                selected={(element) =>
                  element &&
                  React.cloneElement(element, {
                    disabled: true,
                    className:
                      "flex items-center opacity-100 px-0 gap-2 pointer-events-none ",
                  })
                }
              >
                {categories ? (
                  categories.map(({ title, _id }) => (
                    <Option
                      key={title}
                      value={_id}
                      className="flex items-center gap-2"
                    >
                      {title}
                    </Option>
                  ))
                ) : (
                  <></>
                )}
              </Select>
            </div>
            <div className="flex w-full flex-col gap-1">
              <Input
                variant="outlined"
                label="Total Adet"
                name="totalCount"
                required
                color="deep-purple"
                type="number"
                disabled={badgeData?.totalCount === "-999"}
                value={badgeData?.totalCount}
                onChange={handleInputChange}
                //onChange={(e) => {
                //   setBadgeData({ ...badgeData, totalCount: e.target.value });
                //   }}
              />
              <Checkbox
                checked={badgeData?.totalCount == "-999"}
                onChange={() => {
                  if (badgeData?.totalCount !== "-999") {
                    setBadgeData({ ...badgeData, totalCount: "-999" });
                  } else {
                    setBadgeData({ ...badgeData, totalCount: "0" });
                  }
                }}
                className="text-sm h-4  w-4"
                label="Sınırsız"
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <div className="flex gap-2 items-center">
                <Input
                  variant="outlined"
                  label="Ücret"
                  color="deep-purple"
                  required
                  type="number"
                  value={badgeData?.price}
                  onChange={(e) => {
                    if ((e.target.value < 0) & (e.target.value != -999)) {
                      setBadgeData({ ...badgeData, price: 0 });
                    } else {
                      setBadgeData({ ...badgeData, price: e.target.value });
                    }
                  }}
                />
                <p>TL</p>
              </div>
              <p className="text-gray-600 text-xs px-2">
                Ücretsiz gönderim için 0 giriniz
              </p>
            </div>

            <div>
              <Checkbox
                value={"0"}
                checked={
                  badgeData?.attainerRoles?.includes("0") ||
                  badgeData?.attainerRoles?.includes(0)
                }
                onChange={handleCheckBox}
                className="text-sm h-4  w-4"
                label="Kurum"
              />
              <Checkbox
                value={"1"}
                checked={
                  badgeData?.attainerRoles?.includes("1") ||
                  badgeData?.attainerRoles?.includes(1)
                }
                onChange={handleCheckBox}
                className="text-sm h-4  w-4"
                label="Kurum"
              />
              <Checkbox
                value={"2"}
                checked={
                  badgeData?.attainerRoles?.includes("2") ||
                  badgeData?.attainerRoles?.includes(2)
                }
                onChange={handleCheckBox}
                className="text-sm h-4  w-4"
                label="Yetkinlik"
              />
            </div>
          </div>
          <div className="p-2 flex flex-col gap-3 ">
            <label>Yetkinlikler</label>
            <div className="!max-h-[100px] max-w-[80%] justify-center flex flex-wrap gap-3 overflow-y-auto bg-blue-gray-50 p-2 rounded-lg">
              {badgeData.attainerRoles.length > 0 ? (
                users.map((user, index) => {
                  if (badgeData.attainerRoles.includes(user._id)) {
                    return (
                      <div
                        key={index}
                        className="flex gap-1 rounded-lg px-2 py-0.5 bg-teal-400 text-white text-sm items-center"
                      >
                        {user.name + " " + user.surname}
                        <button
                          onClick={() => {
                            setBadgeData({
                              ...badgeData,
                              attainerRoles: badgeData?.attainerRoles?.filter(
                                (r) => r !== user._id
                              ),
                            });
                          }}
                          className="p-1 rounded-lg bg-teal-100"
                        >
                          <img
                            className="h-2.5 w-2.5"
                            src={IconClose}
                            alt="closeIcon"
                          />
                        </button>
                      </div>
                    );
                  }
                })
              ) : (
                <></>
              )}
            </div>
            <Input
              value={searchUser}
              label="Kişi ekle"
              onChange={(e) => {
                setSearchUser(e.target.value);
              }}
              type="search"
              className="bg-white"
            />
            <div className="!max-h-[500px] overflow-y-scroll">
              <div className="flex flex-col gap-3 overflow-y-scroll h-[500px] bg-white p-2 rounded-lg ">
                {filteredData && filteredData?.length !== 0 ? (
                  filteredData?.map((user, index) => (
                    <Checkbox
                      key={index}
                      checked={badgeData?.attainerRoles?.includes(user._id)}
                      onChange={() => {
                        if (badgeData?.attainerRoles?.includes(user._id)) {
                          setBadgeData({
                            ...badgeData,
                            attainerRoles: badgeData?.attainerRoles?.filter(
                              (data) => data !== user._id
                            ),
                          });
                        } else {
                          setBadgeData({
                            ...badgeData,
                            attainerRoles: [
                              ...badgeData.attainerRoles,
                              user._id,
                            ],
                          });
                        }
                      }}
                      label={
                        <div>
                          <Typography color="blue-gray" className="font-medium">
                            {user.name + " " + user.surname}
                          </Typography>
                          <Typography
                            variant="small"
                            color="gray"
                            className="font-normal"
                          >
                            <span>{user.email[0] || ""}</span>
                            <br />
                            <span>{user.phone || ""}</span>
                            <br />
                            <span>
                              <b>Tip:</b>
                              {user.role === 0
                                ? "Kullanıcı"
                                : user.role === 1
                                ? "Kurum"
                                : "Admin"}
                            </span>
                          </Typography>
                        </div>
                      }
                      containerProps={{
                        className: "-mt-5",
                      }}
                    />
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </DialogBody>
      <DialogFooter>
        <div className="flex gap-3 p-2">
          <button
            onClick={() => {
              select2Edit(null);
            }}
            className="bg-red-100 px-3 py-1 rounded-lg border border-white hover:bg-white hover:border-red-500 transition-all hover:scale-[103%]"
          >
            <span className="text-red-600">Vazgeç</span>
          </button>
          <button
            className=" px-3 py-1 rounded-lg border border-white hover:bg-green-300 group hover:border-green-500 transition-all hover:scale-[103%]"
            onClick={() => {
              //handlesubmit
            }}
          >
            <button
              onClick={handleSubmit}
              className="text-green-800 font-semibold group-hover:text-white"
            >
              Güncelle
            </button>
          </button>
        </div>
        {loading ? <Loading label={loadingLabel} /> : <></>}
      </DialogFooter>
    </Dialog>
  );
};

export default UpdateBadge;
