import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { KurumSidebar } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import { IconLogOut, IconNullProfile } from "../../assets/images";
import { setPage } from "../../stores/Page";
import { BadgeNull } from "../../assets/fakeData";

const KurumContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("auth-token");
    if (!token) window.location.href = "/admin";
  }, []);
  const page = useSelector((state) => state.page.current);

  return (
    <div className="flex h-screen w-screen ">
      <KurumSidebar />
      <div className="bg-gray-300 w-full h-screen flex flex-col   overflow-x-scroll scrollbar-hide overflow-y-hidden">
        <div className="w-full bg-teal-400 up-xl:p-8 xl:p-5 down-lg:p-4  items-center flex justify-between sm:p-2 font-semibold text-white uppercase">
          <label>{page || "Kurum Panel"}</label>
          <Menu>
            <MenuHandler>
              <img
                alt="tania andrew"
                className="cursor-pointer bg-gray-100 p-1.5 rounded-full h-14 w-14 down-md:h-10 down-md:w-10"
                src={IconNullProfile}
              />
            </MenuHandler>
            <MenuList>
              <MenuItem
                onClick={() => {
                  navigate("/kurum");
                  dispatch(setPage("kurum"));

                  localStorage.removeItem("auth-token");
                }}
                className="flex items-center gap-2"
              >
                <img className="h-6 w-6" src={IconLogOut} alt="cikis_yap" />{" "}
                Çıkış Yap
              </MenuItem>
            </MenuList>
          </Menu>
        </div>
        <div className="p-5 sm:p-2  h-[calc(100vh-150px)]">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default KurumContainer;
