import { createSlice } from "@reduxjs/toolkit";
export const badge = createSlice({
  name: "badge",
  initialState: { selected: null },

  reducers: {
    setSelectedBadge: (state, action) => {
      state.selected = action.payload;
    },
  },
});

export const { setSelectedBadge } = badge.actions;
export default badge.reducer;
