import { configureStore } from "@reduxjs/toolkit";
import pageReducer from "./Page.js";
import userReducer from "./User.js";
import badgeReducer from "./badge.js"; // Fix the file name to be in lowercase

export default configureStore({
  reducer: {
    page: pageReducer,
    user: userReducer,
    badge: badgeReducer,
  },
});
