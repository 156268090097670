import React, { useEffect, useState } from "react";
import KurumBadgeCard from "../../components/KurumBadgeCard";
import { getAllBadges } from "../../services/badgeService.ts";
import {
  Input,
  Option,
  Select,
  Switch,
  Typography,
} from "@material-tailwind/react";
import { IconSearch } from "../../assets/images/index.js";
import { getAllCategory } from "../../services/categoryService.ts";
import { toast } from "react-toastify";
import KurumSendBadgeDialog from "../../components/KurumSendBadgeDialog.jsx";
import { useSelector } from "react-redux";
import { getMyInfo } from "../../services/authService.ts";
import Loading from "../../components/Loading.jsx";

const KurumBadges = () => {
  const [availableBadges, setAvailableBadges] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [allCategories, setCategories] = useState([]);
  const [isUniqueBadges, setIsUniqueBadges] = useState(false);
  const [selectedBadge, setSelectedBadge] = useState();
  const [userData, setUserData] = useState();

  const [loading, setLoading] = useState(false);
  const fetchUserInfo = async () => {
    const res = await getMyInfo();
    if (res.success) {
      setUserData(res.data);
    } else {
      toast.error("Bağlantı Hatası");
    }
  };

  const fetchBadge = async () => {
    setLoading(true);
    const res = await getAllBadges();
    if (res.success) {
      setAvailableBadges(res.data);
    } else {
      toast.error("Bir hata");
    }
    setLoading(false);
  };
  const fetchCategories = async () => {
    const res = await getAllCategory();
    if (res.success) {
      setCategories(res.data);
    } else {
      toast.error("Bir hata");
    }
  };
  useEffect(() => {
    fetchBadge();
    fetchCategories();
    fetchUserInfo();
  }, []);

  const filteredData = availableBadges?.filter((item) => {
    if (!availableBadges) {
      return [];
    }
    if (isUniqueBadges) {
      return (
        item?.title.toLowerCase().includes(searchText.toLowerCase()) &&
        item?.categoryId.title
          .toLowerCase()
          .includes(selectedOption.toLowerCase()) &&
        item?.attainerRoles?.includes(userData?._id)
      );
    }
    return (
      item?.title.toLowerCase().includes(searchText.toLowerCase()) &&
      item?.categoryId.title
        .toLowerCase()
        .includes(selectedOption.toLowerCase())
    );
  });
  return (
    <div className="flex flex-col items-center  h-full">
      <label className=" w-full px-8 down-md:px-2 text-gray-800 ">
        Göndermek İstediğiniz Rozeti Seçin
      </label>

      <div className="p-2 flex flex-wrap gap-2 items-center">
        <div className="flex gap-2 items-center">
          <Input
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            type="text"
            label="Ara"
            className="bg-white"
          />
          <img className="h-5 w-5" src={IconSearch} alt="search" />
        </div>
        <div>
          {" "}
          <Select
            className="bg-white"
            variant="outlined"
            label="Kategori Seç"
            onChange={(e) => {
              setSelectedOption(e);
            }}
            selected={(element) =>
              element &&
              React.cloneElement(element, {
                disabled: true,
                className:
                  "flex items-center opacity-100 px-0 gap-2 pointer-events-none",
              })
            }
          >
            {" "}
            <Option key={-1} value={""}>
              Tümü
            </Option>
            {allCategories?.map((category, index) => (
              <Option key={index} value={category.title}>
                {category.title}
              </Option>
            ))}
          </Select>
        </div>
        <Switch
          checked={isUniqueBadges}
          onChange={() => setIsUniqueBadges(!isUniqueBadges)}
          label={"Yalnızca Atanmış Rozetler"}
          containerProps={{
            className: "",
          }}
        />
      </div>
      <div className="flex flex-wrap gap-4 overflow-y-scroll scrollbar-hide pb-48 items-center justify-center max-h-screen">
        {filteredData?.length > 0 ? (
          filteredData?.map((badge) => (
            <KurumBadgeCard setSelectedBadge={setSelectedBadge} badge={badge} />
          ))
        ) : (
          <div className="h-[400px] flex items-center font-semibold text-teal-600">
            Aranan Kriterlerde Rozet Bulunamadı
          </div>
        )}
      </div>
      {loading ? <Loading /> : <></>}
      <KurumSendBadgeDialog
        setSelectedBadge={setSelectedBadge}
        selectedBadge={selectedBadge}
      />
    </div>
  );
};

export default KurumBadges;
