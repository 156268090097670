import React from "react";

const KurumBadgeCard = ({ badge, setSelectedBadge }) => {
  const badgeData = badge;
  return (
    <div
      onClick={() => {
        setSelectedBadge(badgeData);
      }}
      className="shadow-lg p-4 bg-white rounded-lg hover:scale-[105%] hover:bg-teal-50 transition-all duration-100  h-[400px] flex flex-col justify-center gap-1 w-80 items-center cursor-pointer"
    >
      <div>
        {" "}
        <img
          className="w-56 sm:w-40 md:w-52 bg-white   shadow-md rounded-lg"
          src={badgeData.badgeImg}
        />{" "}
      </div>
      <label className="text-lg font-semibold text-gray-800 w-full text-center">
        {badgeData.title || "Rozet Başlığı"}
      </label>
      <label className="text-gray-600 text-sm">
        {badgeData.categoryId.title || "Kategori"}
      </label>
      <div className="text-sm text-gray-600">
        <label className="text-gray-900">Stok:</label>{" "}
        {badgeData.restCount === -999 ? "Sınrısız" : badgeData.restCount}
      </div>
    </div>
  );
};

export default KurumBadgeCard;
